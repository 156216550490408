<template>
    <div>
        我是留学
    </div>
</template>

<script>
export default {
    name: 'Study',
    data() {
        return {
            isLoading: false,
        }
    },
    created() {
        // 1、根据路由知道是什么产品
        // 2、现在在哪一步 渲染对应的组件
    },
    methods: {
    },
}
</script>

<style lang="less" scoped>

</style>